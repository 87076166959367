import Repository from "../Repository";
import { getAuthHeader, apiConfig, company } from "../../_helpers/apiHelper";

const resource = "/companies";

export default {
  get(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}`, config);
  },

  getAll(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/all`, config);
  },

  getAllUserCompanies(userId) {
    return Repository.get(`${resource}/all/${userId}`, apiConfig());
  },

  toggleUserCompany(userId, companyId) {
    return Repository.put(
      `${resource}/toggle/${userId}/${companyId}`,
      null,
      apiConfig()
    );
  },

  getAllExternalUsers() {
    return Repository.get(
      `${resource}/external/users/${company()}`,
      apiConfig()
    );
  },

  getCompany(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}`, config);
  },

  getCompanyById(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}`, config);
  },

  getCompanyUsers(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/users`, config);
  },

  getCompanyApplications(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/applications`, config);
  },

  getCompanyTagData(user, companyId) {
    var header = getAuthHeader(user);
    header["Accept-Encoding"] = "br";

    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/tagdata`, config);
  },

  create(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}`, payload, config);
  },

  edit(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${companyId}`, payload, config);
  },

  delete(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${companyId}`, config);
  },

  getTagDataWithMetrics(user, companyId, metrics) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/tagdata${metrics}`, config);
  },

  getTagDataDefaults(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/defaults`, config);
  },

  getTagDataPoints(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/tagdata/count`, config);
  },

  createUser(user, companyId, userPayload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/${companyId}/user`,
      userPayload,
      config
    );
  },

  postWhiteLabelImage(image) {
    return Repository.post(
      `${resource}/white-label/${company()}`,
      image,
      apiConfig()
    );
  },

  postImage(user, companyId, image) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${companyId}/image`, image, config);
  },

  deleteImage(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${companyId}/image`, config);
  },

  latestActivity(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/tag/activity`, config);
  },

  getBatteryStatuses(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/tag/batteries`, config);
  },

  getLatestTagData(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/latesttagdata`, config);
  },

  getDatapointsLastWeek(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/datapointsweek`, config);
  },

  getDatapointsLastDay(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}/datapointsday`, config);
  },

  getSensorDatapointsLastDay(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${resource}/${companyId}/sensordatapoints/day`,
      config
    );
  },

  getTagsWithInformation() {
    return Repository.get(`${resource}/${company()}/tags`, apiConfig());
  },

  getShared(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/shared`, config);
  },
};
